import page404Logo from '../assets/images/illustration_404.svg';
import GoogleLogo from '../assets/images/Google_Logo.png';
import FacebookLogo from '../assets/images/Facebook_Logo.png';
import InstagramLogo from '../assets/images/Instagram_Icon_Outline.png';

import { Person } from '@mui/icons-material'


export const IMAGES = {
    page404Logo,
    GoogleLogo,
    FacebookLogo,
    InstagramLogo
};

export const ICONS = {
    profile: Person
}
