import React from 'react';
import './App.css';
import Routing from './routes';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from '@mui/material/styles';
import { store, persistor } from './redux';
import { SnackbarProvider } from 'notistack';
import { theme } from './utils/theme';

function App() {
    return (
        <SnackbarProvider maxSnack={3}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ThemeProvider theme={theme}>
                        <Routing />
                    </ThemeProvider>
                </PersistGate>
            </Provider>
        </SnackbarProvider>
    );
}

export default App;
