import { Backdrop, Box, CircularProgress, Grid, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import Slider from 'react-slick';
import { useCallback, useEffect, useRef, useState } from 'react';
import { api } from '../../../api';
import { COLORS, theme } from '../../../utils/theme';
import { IMAGES } from '../../../assets';
import { useParams } from 'react-router-dom';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const MainBox = styled(Box)`
    background-color: #1977f2;
    & .slick-track {
        height: 100vh;
        display: flex;
        align-items: center;
    }
    @media screen and (min-width: 3840px) {
        width: calc(100% - 200px);
        padding: 0px 100px;
    }
    @media screen and (max-width: 3840px) {
        width: calc(100% - 200px);
        padding: 0px 100px;
    }
    @media screen and (max-width: 2560px) {
        width: calc(100% - 150px);
        padding: 0px 75px;
    }
    @media screen and (max-width: 1920px) {
        width: calc(100% - 100px);
        padding: 0px 50px;
    }
    @media screen and (max-width: 1280px) {
        width: calc(100% - 80px);
        padding: 0px 40px;
    }
    @media screen and (max-width: 720px) {
        width: calc(100% - 60px);
        padding: 0px 30px;
    }
`;

const SliderContainer = styled(Slider)`
    @media screen and (min-width: 3840px) {
        height: calc(100vh - 300px - 400px);
    }
    @media screen and (max-width: 3840px) {
        height: calc(100vh - 300px - 400px);
    }
    @media screen and (max-width: 2560px) {
        height: calc(100vh - 200px - 255px);
    }
    @media screen and (max-width: 1920px) {
        height: calc(100vh - 280px);
    }
    @media screen and (max-width: 1280px) {
        height: calc(100vh - 230px);
    }
    @media screen and (max-width: 720px) {
        height: calc(100vh - 130px);
    }
`;

const ReviewContainer = styled(Box)`
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: 3840px) {
        gap: 100px;
        height: calc(100vh - 300px);
        padding: 150px 0px;
    }
    @media screen and (max-width: 3840px) {
        gap: 100px;
        height: calc(100vh - 300px);
        padding: 150px 0px;
    }
    @media screen and (max-width: 2560px) {
        gap: 65px;
        height: calc(100vh - 200px);
        padding: 100px 0px;
    }
    @media screen and (max-width: 1920px) {
        gap: 40px;
        height: calc(100vh - 130px);
        padding: 65px 0px;
    }
    @media screen and (max-width: 1280px) {
        gap: 30px;
        height: calc(100vh - 110px);
        padding: 55px 0px;
    }
    @media screen and (max-width: 720px) {
        gap: 20px;
        height: calc(100vh - 70px);
        padding: 35px 0px;
    }
`;

const ReviewHeader = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: ${(props) => (props.is_portrait ? 'wrap' : 'noWrap')};
`;

const ReviewCard = styled(Box)`
    overflow: hidden;
    background-color: ${COLORS.white};
    height: 100%;
    width: 100%;
    @media screen and (min-width: 3840px) {
        border-radius: 60px;
        box-shadow: ${theme.shadows[14]};
    }
    @media screen and (max-width: 3840px) {
        border-radius: 50px;
        box-shadow: ${theme.shadows[12]};
    }
    @media screen and (max-width: 2560px) {
        border-radius: 40px;
        box-shadow: ${theme.shadows[10]};
    }
    @media screen and (max-width: 1920px) {
        border-radius: 30px;
        box-shadow: ${theme.shadows[8]};
    }
    @media screen and (max-width: 1280px) {
        border-radius: 20px;
        box-shadow: ${theme.shadows[6]};
    }
    @media screen and (max-width: 720px) {
        border-radius: 10px;
        box-shadow: ${theme.shadows[4]};
    }
`;

const ReviewCardContainer = styled(Box)`
    overflow: hidden;
    height: 100%;
    @media screen and (min-width: 3840px) {
        margin: 100px;
        height: calc(100% - 100px)
    }
    @media screen and (max-width: 3840px) {
        margin: 100px;
        height: calc(100% - 100px)
    }
    @media screen and (max-width: 2560px) {
        margin: 75px;
        height: calc(100% - 75px)
    }
    @media screen and (max-width: 1920px) {
        margin: 50px;
        height: calc(100% - 50px)
    }
    @media screen and (max-width: 1280px) {
        margin: 40px;
        height: calc(100% - 40px)
    }
    @media screen and (max-width: 720px) {
        margin: 30px;
        height: calc(100% - 30px)
    }
`;

const FacebookFeedContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: end;
`;

const Likes = styled(Typography)`
    color: ${COLORS.white};
    text-align: ${(props) => props.is_portrait && 'center'};
    @media screen and (min-width: 3840px) {
        font-size: 180px;
    }
    @media screen and (max-width: 3840px) {
        font-size: 180px;
    }
    @media screen and (max-width: 3100px) {
        font-size: 140px;
    }
    @media screen and (max-width: 2560px) {
        font-size: 125px;
    }
    @media screen and (max-width: 1920px) {
        font-size: 75px;
    }
    @media screen and (max-width: 1280px) {
        font-size: 65px;
    }
    @media screen and (max-width: 950px) {
        font-size: 55px;
    }
    @media screen and (max-width: 800px) {
        font-size: 45px;
    }
    @media screen and (max-width: 720px) {
        font-size: 35px;
    }
    @media screen and (min-height: 3840px) {
        font-size: ${(props) => props.is_portrait && '200px'};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        font-size: ${(props) => props.is_portrait && '90px'};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        font-size: ${(props) => props.is_portrait && '70px'};
    }
`;

const FacebookText = styled(Typography)`
    color: ${COLORS.white};
    @media screen and (min-width: 3840px) {
        font-size: 180px;
    }
    @media screen and (max-width: 3840px) {
        font-size: 180px;
    }
    @media screen and (max-width: 3100px) {
        font-size: 140px;
    }
    @media screen and (max-width: 2560px) {
        font-size: 125px;
    }
    @media screen and (max-width: 1920px) {
        font-size: 75px;
    }
    @media screen and (max-width: 1280px) {
        font-size: 65px;
    }
    @media screen and (max-width: 950px) {
        font-size: 55px;
    }
    @media screen and (max-width: 800px) {
        font-size: 45px;
    }
    @media screen and (max-width: 720px) {
        font-size: 35px;
    }
    @media screen and (min-height: 3840px) {
        font-size: ${(props) => props.is_portrait && '200px'};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        font-size: ${(props) => props.is_portrait && '90px'};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        font-size: ${(props) => props.is_portrait && '70px'};
    }
`;

const FacebookImageContainer = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.is_portrait ? 'space-between' : 'center')};
`;

const FacebookImage = styled('img')`
    border-radius: 50%;
    @media screen and (min-width: 3840px) {
        height: 260px;
        border: 12px solid ${COLORS.white};
    }
    @media screen and (max-width: 3840px) {
        height: 260px;
        border: 12px solid ${COLORS.white};
    }
    @media screen and (max-width: 3100px) {
        height: 230px;
        border: 11px solid ${COLORS.white};
    }
    @media screen and (max-width: 2560px) {
        height: 200px;
        border: 10px solid ${COLORS.white};
    }
    @media screen and (max-width: 1920px) {
        height: 130px;
        border: 8px solid ${COLORS.white};
    }
    @media screen and (max-width: 1280px) {
        height: 100px;
        border: 6px solid ${COLORS.white};
    }
    @media screen and (max-width: 950px) {
        height: 90px;
        border: 5px solid ${COLORS.white};
    }
    @media screen and (max-width: 800px) {
        height: 70px;
        border: 4px solid ${COLORS.white};
    }
    @media screen and (max-width: 720px) {
        height: 50px;
        border: 3px solid ${COLORS.white};
    }
    @media screen and (min-height: 3840px) {
        height: ${(props) => props.is_portrait && '300px'};
        border: ${(props) => props.is_portrait && `12px solid ${COLORS.white}`};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        height: ${(props) => props.is_portrait && '140px'};
        border: ${(props) => props.is_portrait && `8px solid ${COLORS.white}`};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        height: ${(props) => props.is_portrait && '120px'};
        border: ${(props) => props.is_portrait && `8px solid ${COLORS.white}`};
    }
`;

const CommentContainer = styled(Box)`
    height: calc(100% - ${(props) => props.name_containe_height}px);
    overflow: hidden;
`;

const DetailsText = styled(Typography)`
    color: ${COLORS.black};
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.num_lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (min-width: 3840px) {
        font-size: 130px;
        line-height: 145px;
    }
    @media screen and (max-width: 3840px) {
        font-size: 130px;
        line-height: 145px;
    }
    @media screen and (max-width: 3100px) {
        font-size: 100px;
        line-height: 125px;
    }
    @media screen and (max-width: 2560px) {
        font-size: 90px;
        line-height: 115px;
    }
    @media screen and (max-width: 1920px) {
        font-size: 55px;
        line-height: 70px;
    }
    @media screen and (max-width: 1280px) {
        font-size: 45px;
        line-height: 60px;
    }
    @media screen and (max-width: 950px) {
        font-size: 35px;
        line-height: 50px;
    }
    @media screen and (max-width: 800px) {
        font-size: 25px;
        line-height: 40px;
    }
    @media screen and (max-width: 720px) {
        font-size: 25px;
        line-height: 40px;
    }
    @media screen and (min-height: 3840px) {
        font-size: ${(props) => props.is_portrait && '130px'};
        line-height: ${(props) => props.is_portrait && '150px'};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        font-size: ${(props) => props.is_portrait && '70px'};
        line-height: ${(props) => props.is_portrait && '85px'};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        font-size: ${(props) => props.is_portrait && '45px'};
        line-height: ${(props) => props.is_portrait && '60px'};
    }
`;

const NameContainer = styled(Box)`
    display: flex !important;
    align-items: center;
    flex-direction: column;
    @media screen and (min-width: 3840px) {
        padding-bottom: 50px;
    }
    @media screen and (max-width: 3840px) {
        padding-bottom: 50px;
    }
    @media screen and (max-width: 2560px) {
        padding-bottom: 40px;
    }
    @media screen and (max-width: 1920px) {
        padding-bottom: 30px;
    }
    @media screen and (max-width: 1280px) {
        padding-bottom: 30px;
    }
    @media screen and (max-width: 720px) {
        padding-bottom: 10px;
    }
`;

const ProfileImage = styled('img')`
    border-radius: 50%;
    @media screen and (min-width: 3840px) {
        height: 240px;
        margin-left: 30px;
        margin-right: 30px;
    }
    @media screen and (max-width: 3840px) {
        height: 240px;
        margin-left: 30px;
        margin-right: 30px;
    }
    @media screen and (max-width: 3100px) {
        height: 160px;
        margin-left: 30px;
        margin-right: 30px;
    }
    @media screen and (max-width: 2560px) {
        height: 150px;
        margin-left: 25px;
        margin-right: 25px;
    }
    @media screen and (max-width: 1920px) {
        height: 100px;
        margin-left: 15px;
        margin-right: 15px;
    }
    @media screen and (max-width: 1280px) {
        height: 80px;
        margin-left: 10px;
        margin-right: 10px;
    }
    @media screen and (max-width: 950px) {
        height: 70px;
        margin-left: 8px;
        margin-right: 8px;
    }
    @media screen and (max-width: 800px) {
        height: 60px;
        margin-left: 7px;
        margin-right: 7px;
    }
    @media screen and (max-width: 720px) {
        height: 50px;
        margin-left: 6px;
        margin-right: 6px;
    }
    @media screen and (min-height: 3840px) {
        height: ${(props) => props.is_portrait && '240px'};
        margin-left: ${(props) => props.is_portrait && '30px'};
        margin-right: ${(props) => props.is_portrait && '30px'};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        height: ${(props) => props.is_portrait && '150px'};
        margin-left: ${(props) => props.is_portrait && '25px'};
        margin-right: ${(props) => props.is_portrait && '25px'};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        height: ${(props) => props.is_portrait && '80px'};
        margin-left: ${(props) => props.is_portrait && '10px'};
        margin-right: ${(props) => props.is_portrait && '10px'};
    }
`;

const PatientImage = styled('img')`
    width: ${(props) => (props.is_portrait ? '70%' : '100%')};
    margin-top: ${(props) => (props.is_portrait ? '20px' : '0px')};
    @media screen and (min-height: 3840px) {
        margin-top: ${(props) => (props.is_portrait ? '120px' : '0px')};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        margin-top: ${(props) => (props.is_portrait ? '60px' : '0px')};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        margin-top: ${(props) => (props.is_portrait ? '30px' : '0px')};
    }
`;

const NameText = styled(Typography)`
    color: ${COLORS.white};
    @media screen and (min-width: 3840px) {
        font-size: 150px;
    }
    @media screen and (max-width: 3840px) {
        font-size: 150px;
    }
    @media screen and (max-width: 3100px) {
        font-size: 95px;
    }
    @media screen and (max-width: 2560px) {
        font-size: 85px;
    }
    @media screen and (max-width: 1920px) {
        font-size: 50px;
    }
    @media screen and (max-width: 1280px) {
        font-size: 40px;
    }
    @media screen and (max-width: 950px) {
        font-size: 30px;
    }
    @media screen and (max-width: 800px) {
        font-size: 20px;
    }
    @media screen and (max-width: 720px) {
        font-size: 20px;
    }
    @media screen and (min-height: 3840px) {
        font-size: ${(props) => props.is_portrait && '150px'};
    }
    @media screen and (min-height: 1920px) and (max-height: 3839px) {
        font-size: ${(props) => props.is_portrait && '65px'};
    }
    @media screen and (min-height: 1280px) and (max-height: 1919px) {
        font-size: ${(props) => props.is_portrait && '40px'};
    }
`;

export const FacebookReview = () => {
    const [is_portrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
    const textContainerRef = useRef(null);
    const nameContainerRef = useRef(null);
    const detailsTextRef = useRef(null);
    const patientImageRef = useRef(null);
    const [num_lines, setNumLines] = useState(0);
    const [name_containe_height, setNameContainerHeight] = useState(0);
    const [reviewDetails, setReviewDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [initialSlide, setInitialSlide] = useState('unset');
    const { enqueueSnackbar } = useSnackbar();

    const params = useParams();
    const client_id = params.client_id;
    const run = new URLSearchParams(window.location.search).get('run') || 0;
    const totalDuration = new URLSearchParams(window.location.search).get('duration') || 40;
    const totalFeed = new URLSearchParams(window.location.search).get('total_feed') || 40;
    const durationPerField = new URLSearchParams(window.location.search).get('each_feed_time') || 10;
    const numberofFeedInOneRun = totalDuration / durationPerField;

    const handleResize = () => {
        setIsPortrait(window.innerHeight > window.innerWidth);

        const containerHeight = textContainerRef.current?.clientHeight;
        const lineHeightData = parseInt(getComputedStyle(detailsTextRef.current).lineHeight);
        const newNumLines = Math.floor(containerHeight / lineHeightData);
        setNumLines(newNumLines);
    };

    const handleImageLoad = () => {
        if (patientImageRef.current) {
            setNameContainerHeight(nameContainerRef.current?.clientHeight);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        if (patientImageRef.current) {
            patientImageRef.current.addEventListener('load', handleImageLoad);
        }
        return () => {
            window.removeEventListener('resize', handleResize);
            if (patientImageRef.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                patientImageRef.current.removeEventListener('load', handleImageLoad);
            }
        };
    }, []);

    useEffect(() => {
        if (reviewDetails) {
            handleResize();
        }
        setNameContainerHeight(nameContainerRef.current?.clientHeight);
    }, [nameContainerRef.current?.clientHeight, reviewDetails]);

    const getFacebookInfo = useCallback(async () => {
        try {
            setLoading(true);
            if (client_id) {
                const { data } = await api.review.facebookFeed({ params: { client_id, limit: totalFeed } });
                setReviewDetails(data?.result);
                let slideToFirst = (run * numberofFeedInOneRun) % data?.result?.posts?.length;
                setInitialSlide(Math.round(slideToFirst));
                setLoading(false);
                if (!data.success) {
                    enqueueSnackbar(data.message, { variant: 'error' });
                }
            }
        } catch (error) {
            setLoading(false);
            enqueueSnackbar('Our server error' + error.message, { variant: 'error' });
        }
    }, [client_id, run, numberofFeedInOneRun, enqueueSnackbar, totalFeed]);

    useEffect(() => {
        getFacebookInfo();
    }, [getFacebookInfo]);

    return (
        <MainBox>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {reviewDetails && initialSlide !== 'unset' && !loading && (
                <ReviewContainer>
                    <ReviewHeader is_portrait={is_portrait}>
                        <FacebookImageContainer is_portrait={is_portrait}>
                            <FacebookImage is_portrait={is_portrait} src={IMAGES.FacebookLogo} />
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <ProfileImage is_portrait={is_portrait} src={reviewDetails.profile}></ProfileImage>
                                <NameText is_portrait={is_portrait}>{reviewDetails.name}</NameText>
                            </Box>
                        </FacebookImageContainer>
                        <FacebookFeedContainer>
                            <NameText is_portrait={is_portrait}>{reviewDetails?.total_posts || 0} Posts</NameText>
                            <NameText is_portrait={is_portrait}>{reviewDetails?.followers_count || 0} Followers</NameText>
                        </FacebookFeedContainer>
                    </ReviewHeader>
                    <SliderContainer
                        pauseOnHover={false}
                        arrows={false}
                        dots={false}
                        nextArrow={false}
                        initialSlide={initialSlide}
                        autoplaySpeed={durationPerField * 1000}
                        autoplay={true}
                        {...settings}
                    >
                        {reviewDetails &&
                            reviewDetails?.posts?.map((post, key) => {
                                return (
                                    <ReviewCard key={key}>
                                        <ReviewCardContainer>
                                            <Grid container sx={{ height: '100%' }} columnSpacing={{ xs: 2, sm: 4, md: 6, lg: 8, xl: 10 }}>
                                                <Grid item xs={is_portrait ? 12 : 8} sx={{ height: '100%' }}>
                                                    <NameContainer ref={nameContainerRef}>
                                                        {is_portrait && (
                                                            <PatientImage
                                                                ref={patientImageRef}
                                                                is_portrait={is_portrait}
                                                                src={post?.url}
                                                                alt="patientImage"
                                                            />
                                                        )}
                                                    </NameContainer>
                                                    <CommentContainer name_containe_height={name_containe_height} ref={textContainerRef}>
                                                        <DetailsText is_portrait={is_portrait} ref={detailsTextRef} num_lines={num_lines}>
                                                            {post.caption}
                                                        </DetailsText>
                                                    </CommentContainer>
                                                </Grid>
                                                {!is_portrait && (
                                                    <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <PatientImage src={post?.url} alt="patientImage" />
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </ReviewCardContainer>
                                    </ReviewCard>
                                );
                            })}
                    </SliderContainer>
                </ReviewContainer>
            )}
        </MainBox>
    );
};
