import { COLORS } from '.';

export default function componentStyleOverrides(theme) {
    return {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: '600',
                    textTransform: 'none',
                    '&.MuiButton-outlinedPrimary': {
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            color: COLORS.white
                        }
                    },
                    '&.MuiButton-contained ': {
                        color: COLORS.white,
                        borderRadius: '.625rem',
                        fontSize: 15,
                        boxShadow: 'none',
                        textShadow: '0 -1px 0 rgb(0 0 0 / 12%)',
                        '&:hover': {
                            opacity: 0.9,
                            backgroundColor: COLORS.buttonHover
                        }
                    }
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    height: 42
                }
            }
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    top: -6,
                    '&.Mui-focused': {
                        color: COLORS.primary.light
                    }
                }
            }
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    color: COLORS.darkShadesGray,
                    borderRadius: 12,
                    '& fieldset': {
                        transition: 'all .3s',
                        borderColor: COLORS.border
                    },

                    '&.Mui-focused fieldset': {
                        transition: 'all .3s',
                        borderColor: COLORS.primary.light,
                        borderWidth: '1px !important',
                        boxShadow: '0px 0px 0px 4px rgba(0, 144, 237, 0.1)'
                    },
                    '&:hover fieldset': {
                        borderColor: `${COLORS.primary.light} !important`
                    }
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    color: 'white',
                    borderRadius: '12px important',
                    '& svg': {
                        color: 'white !important'
                    }
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: `1px solid ${COLORS.border}`
                }
            }
        },
        MuiSkeleton: {
            styleOverrides: {
                root: {
                    backgroundColor: COLORS.border
                }
            }
        }
    };
}
