import client, { METHODS } from './client';

export const api = {
    review: {
        googleReview: (params) =>
            client({
                url: `/get_client_google_reviews`,
                method: METHODS.GET,
                ...params
            }),
        facebookFeed: (params) =>
            client({
                url: `/get_facebook_post`,
                method: METHODS.GET,
                ...params
            }),
        instagramFeed: (params) =>
            client({
                url: `/get_instagram_post`,
                method: METHODS.GET,
                ...params
            })
    }
};
