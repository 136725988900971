import { Backdrop, Box, CircularProgress, Rating, styled, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import Slider from 'react-slick';
import { useCallback, useEffect, useRef, useState } from 'react';
import { api } from '../../../api';
import { COLORS } from '../../../utils/theme';
import { useParams } from 'react-router-dom';
import { IMAGES } from '../../../assets';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const MainBox = styled(Box)`
    background-color: white;
    & .slick-track {
        height: 100vh;
        display: flex;
        align-items: center;
    }

    @media screen and (min-width: 3840px) {
        width: calc(100% - 800px);
        padding: 0px 400px;
    }
    @media screen and (max-width: 3840px) {
        width: calc(100% - 800px);
        padding: 0px 400px;
    }
    @media screen and (max-width: 2560px) {
        width: calc(100% - 600px);
        padding: 0px 300px;
    }
    @media screen and (max-width: 1920px) {
        width: calc(100% - 400px);
        padding: 0px 200px;
    }
    @media screen and (max-width: 1680px) {
        width: calc(100% - 200px);
        padding: 0px 100px;
    }
    @media screen and (max-width: 1280px) {
        width: calc(100% - 100px);
        padding: 0px 50px;
    }
    @media screen and (max-width: 640px) {
        width: calc(100% - 60px);
        padding: 0px 30px;
    }
`;

const ReviewCard = styled(Box)`
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;

    @media screen and (min-width: 3840px) {
        height: calc(100vh - 400px);
        padding: 200px 0px;
    }
    @media screen and (max-width: 3840px) {
        height: calc(100vh - 400px);
        padding: 200px 0px;
    }
    @media screen and (max-width: 3840px) {
        height: calc(100vh - 300px);
        padding: 150px 0px;
    }
    @media screen and (max-width: 1920px) {
        height: calc(100vh - 200px);
        padding: 100px 0px;
    }
    @media screen and (max-width: 1680px) {
        height: calc(100vh - 100px);
        padding: 50px 0px;
    }
    @media screen and (max-width: 1680px) {
        height: calc(100vh - 100px);
        padding: 50px 0px;
    }
    @media screen and (max-width: 640px) {
        height: calc(100vh - 200px);
        padding: 100px 0px;
    }
`;

const ReviewHeader = styled(Box)`
    display: flex;
    justify-content: ${(props) => (props.is_portrait ? 'center' : 'space-between')};
    flex-wrap: ${(props) => (props.is_portrait ? 'wrap' : 'noWrap')};
    gap: 25px;
`;

const RatingBox = styled(Box)`
    display: flex;
    align-items: center;
    width: 100%;
    span {
        width: 100%;
        display: flex;
        justify-content: space-around;
        gap: 20px;

        @media screen and (min-width: 3840px) {
            font-size: 400px;
        }
        @media screen and (max-width: 3840px) {
            font-size: 400px;
        }
        @media screen and (max-width: 3380px) {
            font-size: 350px;
        }
        @media screen and (max-width: 3085px) {
            font-size: 300px;
        }
        @media screen and (max-width: 2790px) {
            font-size: 250px;
        }
        @media screen and (max-width: 2300px) {
            font-size: 200px;
        }
        @media screen and (max-width: 2000px) {
            font-size: 170px;
        }
        @media screen and (max-width: 1310px) {
            font-size: 150px;
        }
        @media screen and (max-width: 1260px) {
            font-size: 120px;
        }
        @media screen and (max-width: 965px) {
            font-size: 90px;
        }
        @media screen and (max-width: 770px) {
            font-size: 70px;
        }
        @media screen and (max-width: 670px) {
            font-size: 50px;
        }
        @media screen and (max-width: 640px) {
            font-size: 70px;
        }
        @media screen and (max-width: 485px) {
            font-size: 60px;
            gap: 10px;
        }
        @media screen and (max-width: 395px) {
            font-size: 50px;
            gap: 0px;
        }
    }
`;

const GoogleImageContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px'
});

const GoogleImage = styled('img')`
    @media screen and (min-width: 3840px) {
        height: 500px;
    }
    @media screen and (max-width: 3840px) {
        height: 500px;
    }
    @media screen and (max-width: 3380px) {
        height: 450px;
    }
    @media screen and (max-width: 3085px) {
        height: 400px;
    }
    @media screen and (max-width: 2790px) {
        height: 350px;
    }
    @media screen and (max-width: 2300px) {
        height: 280px;
    }
    @media screen and (max-width: 2000px) {
        height: 200px;
    }
    @media screen and (max-width: 1310px) {
        height: 200px;
    }
    @media screen and (max-width: 1260px) {
        height: 150px;
    }
    @media screen and (max-width: 965px) {
        height: 100px;
    }
    @media screen and (max-width: 770px) {
        height: 60px;
    }
    @media screen and (max-width: 670px) {
        height: 50px;
    }
    @media screen and (max-width: 640px) {
        height: 100px;
    }
`;

const GoogleImageText = styled(Typography)`
    color: ${COLORS.black};
    @media screen and (min-width: 3840px) {
        font-size: 80px;
    }
    @media screen and (max-width: 3840px) {
        font-size: 80px;
    }
    @media screen and (max-width: 2560px) {
        font-size: 60px;
    }
    @media screen and (max-width: 1920px) {
        font-size: 50px;
    }
    @media screen and (max-width: 1680px) {
        font-size: 40px;
    }
    @media screen and (max-width: 1280px) {
        font-size: 30px;
    }
    @media screen and (max-width: 640px) {
        font-size: 20px;
    }
`;

const DetailsText = styled(Typography)`
    font-weight: bold;
    color: ${COLORS.black};
    text-align: justify;
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.num_lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media screen and (min-width: 3840px) {
        font-size: 80px;
        line-height: 95px;
    }
    @media screen and (max-width: 3840px) {
        font-size: 80px;
        line-height: 95px;
    }
    @media screen and (max-width: 2560px) {
        font-size: 60px;
        line-height: 75px;
    }
    @media screen and (max-width: 1920px) {
        font-size: 50px;
        line-height: 65px;
    }
    @media screen and (max-width: 1680px) {
        font-size: 40px;
        line-height: 55px;
    }
    @media screen and (max-width: 1280px) {
        font-size: 30px;
        line-height: 45px;
    }
    @media screen and (max-width: 640px) {
        font-size: 20px;
        line-height: 35px;
    }
`;

const NameText = styled(Typography)`
    font-weight: bold;
    color: ${COLORS.error};
    align-self: flex-end;
    @media screen and (min-width: 3840px) {
        font-size: 80px;
    }
    @media screen and (max-width: 3840px) {
        font-size: 80px;
    }
    @media screen and (max-width: 2560px) {
        font-size: 60px;
    }
    @media screen and (max-width: 1920px) {
        font-size: 50px;
    }
    @media screen and (max-width: 1680px) {
        font-size: 40px;
    }
    @media screen and (max-width: 1280px) {
        font-size: 30px;
    }
    @media screen and (max-width: 640px) {
        font-size: 20px;
    }
`;

const CommentContainer = styled(Box)`
    height: 100%;
    overflow: hidden;
`;

// const fakeReview = {
//     reviews: [
//         {
//             comment: 'test comment',
//             reviewer: {
//                 displayName: 'sanjay'
//             }
//         }
//     ]
// };

export const GoogleReview = () => {
    const [reviewDetails, setReviewDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [is_portrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
    const textContainerRef = useRef(null);
    const detailsTextRef = useRef(null);
    const [initialSlide, setInitialSlide] = useState('unset');
    const [num_lines, setNumLines] = useState(0);
    const params = useParams();
    const client_id = params.client_id;
    const run = new URLSearchParams(window.location.search).get('run') || 0;
    const totalDuration = new URLSearchParams(window.location.search).get('duration') || 40;
    const totalFeed = new URLSearchParams(window.location.search).get('total_feed') || 40;
    const durationPerField = new URLSearchParams(window.location.search).get('each_feed_time') || 10;
    const numberofFeedInOneRun = totalDuration / durationPerField;

    const handleResize = () => {
        setIsPortrait(window.innerHeight > window.innerWidth);

        const containerHeight = textContainerRef.current?.clientHeight;
        const lineHeightData = (detailsTextRef.current && parseInt(getComputedStyle(detailsTextRef.current).lineHeight)) || 0;
        const newNumLines = Math.floor(containerHeight / lineHeightData);
        setNumLines(newNumLines);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (reviewDetails) {
            handleResize();
        }
    }, [reviewDetails]);

    const getGoogleInfo = useCallback(async () => {
        try {
            setLoading(true);
            if (client_id) {
                const { data } = await api.review.googleReview({ params: { client_id, limit: totalFeed } });
                if (data?.result?.reviews) {
                    setReviewDetails(data?.result);
                    let slideToFirst = (run * numberofFeedInOneRun) % data?.result?.reviews?.length;
                    setInitialSlide(Math.round(slideToFirst));
                }

                setLoading(false);
                if (!data.success) {
                    enqueueSnackbar(data.message, { variant: 'error' });
                }
            }
        } catch (error) {
            setLoading(false);
            enqueueSnackbar('Our server error' + error.message, { variant: 'error' });
        }
    }, [client_id, run, numberofFeedInOneRun, enqueueSnackbar, totalFeed]);

    useEffect(() => {
        getGoogleInfo();
    }, [getGoogleInfo]);

    return (
        <MainBox is_portrait={is_portrait}>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {reviewDetails && initialSlide !== 'unset' && !loading && (
                <Slider
                    pauseOnHover={false}
                    arrows={false}
                    dots={false}
                    initialSlide={initialSlide}
                    nextArrow={false}
                    autoplaySpeed={durationPerField * 1000}
                    autoplay={true}
                    {...settings}
                >
                    {reviewDetails?.reviews?.map((item, key) => {
                        return (
                            <ReviewCard key={key}>
                                <ReviewHeader is_portrait={is_portrait}>
                                    <GoogleImageContainer>
                                        <GoogleImage src={IMAGES.GoogleLogo} />
                                        <GoogleImageText>Reviews</GoogleImageText>
                                    </GoogleImageContainer>
                                    <RatingBox>
                                        <Rating size="large" name="read-only" value={5} readOnly />
                                    </RatingBox>
                                </ReviewHeader>
                                <CommentContainer ref={textContainerRef}>
                                    <DetailsText ref={detailsTextRef} num_lines={num_lines}>
                                        {item.comment}
                                    </DetailsText>
                                </CommentContainer>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <NameText>{item.reviewer?.displayName}</NameText>
                                </Box>
                            </ReviewCard>
                        );
                    })}
                </Slider>
            )}
            {!loading && !reviewDetails?.reviews && (
                <Box sx={{ display: 'flex', height: '100vh', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="h3">No google review founded</Typography>
                </Box>
            )}
        </MainBox>
    );
};
