import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Page404 from '../components/ErrorPages/404';
import PlainLayout from '../layout/PlainLayout';
import { useRoutes } from '../hooks/useRoutes';

const Routing = ({ baseData, ...props }) => {
    const { plainLayoutRoutes } = useRoutes();

    return (
        <Routes {...props}>
            <Route element={<PlainLayout />}>
                {plainLayoutRoutes.map(({ id: key, ...otherData }, index) => (
                    <Route index key={`plane_${index}`} {...otherData} />
                ))}
            </Route>
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
};

export default Routing;
