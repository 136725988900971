export const PAGES = {
    GOOGLE_REVIEW: {
        id: 1,
        url: '/reviews/:client_id/google',
        name: 'Google Review'
    },
    FACEBOOK_REVIEW: {
        id: 2,
        url: '/socialV3/:client_id/facebook',
        name: 'Facebook Review'
    },
    INSTAGRAM_REVIEW: {
        id: 3,
        url: '/socialV2/:client_id/instagram',
        name: 'Instagram Review'
    }
};