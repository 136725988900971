import React, { useMemo } from 'react';
import { PAGES } from '../utils/urls';

//Clients
import { GoogleReview } from '../containers/client/GoogleReview';
import { FacebookReview } from '../containers/client/FacebookReview';
import { InstagramReview } from '../containers/client/InstagramReview';

export const useRoutes = () => {
    const Routes = [
        {
            id: 'review-google',
            path: PAGES.GOOGLE_REVIEW.url,
            name: PAGES.GOOGLE_REVIEW.name,
            element: <GoogleReview />,
            exact: true,
            hasPlainLayout: true
        },
        {
            id: 'review-facebook',
            path: PAGES.FACEBOOK_REVIEW.url,
            name: PAGES.FACEBOOK_REVIEW.name,
            element: <FacebookReview />,
            exact: true,
            hasPlainLayout: true
        },
        {
            id: 'review-instagram',
            path: PAGES.INSTAGRAM_REVIEW.url,
            name: PAGES.INSTAGRAM_REVIEW.name,
            element: <InstagramReview />,
            exact: true,
            hasPlainLayout: true
        }
    ];

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const plainLayoutRoutes = useMemo(() => {
        return Routes.filter((value) => value.hasPlainLayout);
    });

    return {
        plainLayoutRoutes,
        allRoutes: Routes
    };
};
